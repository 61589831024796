module controllers {
  export module applicationcore {
    export class azureADCtrl {
      static $inject = [
        "$scope",
        "userAccountService",
        "$rootScope",
        "generalService",
        "$cookies",
        "$http",
        "$location",
        "descriptionService",
        "bsLoadingOverlayService",
        "$state",
        "$stateParams",
        "$q",
        "ENV",
        "entityService",
        "msalAuthenticationService",
      ];

      loginDetails: interfaces.applicationcore.ILoginDetails;

      constructor(
        private $scope: ng.IScope,
        private userAccountService: interfaces.applicationcore.IUserAccountService,
        private $rootScope: interfaces.applicationcore.IRootScope,
        public generalService: interfaces.applicationcore.IGeneralService,
        private $cookies: ng.cookies.ICookiesService,
        private $http: ng.IHttpService,
        private $location: ng.ILocationService,
        private descriptionService: services.applicationcore.descriptionService,
        private bsLoadingOverlayService,
        public $state: ng.ui.IStateService,
        public $stateParams: ng.ui.IStateParamsService,
        private $q: ng.IQService,
        private ENV: interfaces.applicationcore.serverConfig,
        private entityService: interfaces.applicationcore.IEntityService,
        private msalAuthenticationService
      ) {
        
        let account = this.msalAuthenticationService.getUser();

        this.msalAuthenticationService
          .acquireTokenSilent(this.ENV.azureADConfig.consentScopes)
          .then((accessToken) => {
            this.doMSALLogin(accessToken);
          })
          .catch((error) => {
            this.msalAuthenticationService
              .loginPopup()
              .then((accessToken) => {
                this.doMSALLogin(accessToken);
              })
              .catch((error) => {});
          });
      }

      doMSALLogin(accessToken) {
        var user = this.msalAuthenticationService.getUser();
        if (user) {
          //this.$rootScope.isLoggedIn = true;  // theres a watch on this property, which tries to load rights if this is true...
          this.$rootScope.userFullName = user.name;
          this.$rootScope.userToken = accessToken;

          this.loginDetails = {
            userName: user.displayableId,
            password: "AADAuth",
            passwordIsHashed: false,
            enumAuthenticationType: 3,
            token: accessToken,
          };

          this.$http.defaults.headers.common["Authorization"] =
            "Bearer " + accessToken;

          if (
            location.hostname === "localhost" ||
            location.hostname === "127.0.0.1" ||
            location.protocol === "http:"
          ) {
            this.$cookies.put("userFullName", this.$rootScope.userFullName);
            this.$cookies.put("userToken", this.$rootScope.userToken);
          } else {
            this.$cookies.put("userFullName", this.$rootScope.userFullName, {
              samesite: "None",
              secure: true,
            });
            this.$cookies.put("userToken", this.$rootScope.userToken, {
              samesite: "None",
              secure: true,
            });
          }

          this.loginUser();
        }
      }

      loginUser(): void {
        if (this.loginDetails) {
          //this.removeStyleSheets();

          this.bsLoadingOverlayService.wrap(
            {
              referenceId: "user.login",
            },
            () => {
              delete this.$http.defaults.headers.common["Accept"];

              if (this.loginDetails.enumAuthenticationType == 1)
                return this.userAccountService.loginUser().save(
                  this.loginDetails,
                  (data) => {
                    this.successLogin(data);
                  },
                  (data) => {
                    this.failureLogin(data);
                  }
                ).$promise;
              if (this.loginDetails.enumAuthenticationType == 2)
                return this.userAccountService.loginADUser().save(
                  this.loginDetails,
                  (data) => {
                    this.successLogin(data);
                  },
                  (data) => {
                    this.failureLogin(data);
                  }
                ).$promise;
              if (this.loginDetails.enumAuthenticationType == 3)
                return this.userAccountService.loginAADUser().save(
                  this.loginDetails,
                  (data) => {
                    this.successLogin(data);
                  },
                  (data) => {
                    this.failureLogin(data);
                  }
                ).$promise;
            }
          );
        }
      }

      successLogin(data: interfaces.applicationcore.IUser): void {
        //Login Success if token is received
        if (data.Token) {
          //this.$rootScope.isLoggedIn = true;
          this.$rootScope.userFullName = data.FirstName + " " + data.Surname;
          this.$rootScope.userId = data.UserId;
          this.$rootScope.sessionDoesNotExpire = false;
          this.$rootScope.userToken = data.Token;
          this.$rootScope.userEmail = data.Email;
          this.$rootScope.isLoggedIn = true; //rather leave this after all rootScope variables are set, as there is a Watch on this field

          if (
            location.hostname === "localhost" ||
            location.hostname === "127.0.0.1" ||
            location.protocol === "http:"
          ) {
            this.$cookies.put("userFullName", this.$rootScope.userFullName);
            this.$cookies.put("userId", this.$rootScope.userId);
            this.$cookies.put("userToken", data.Token);
          } else {
            this.$cookies.put("userFullName", this.$rootScope.userFullName, {
              samesite: "None",
              secure: true,
            });
            this.$cookies.put("userId", this.$rootScope.userId, {
              samesite: "None",
              secure: true,
            });
            this.$cookies.put("userToken", data.Token, {
              samesite: "None",
              secure: true,
            });
          }

          this.$http.defaults.headers.common["Authorization"] =
            "Bearer " + this.$rootScope.userToken;

          this.loadDescriptions();

          var msgHandler = {
            Messages: [
              {
                Description: "",
                Detail: "",
                MessageType: 3,
                MessageItems: [
                  {
                    LocaleDescription: "Success",
                    TypeEnum: 3,
                    TypeId: 3,
                  },
                ],
              },
            ],
          };

          this.generalService.displayMessageHandler(
            <interfaces.applicationcore.IMessageHandler>msgHandler
          );

          if (data.PasswordExpired) {
            this.$state.go("changePassword");
          } else {
            if (
              this.$rootScope.previousPath &&
              this.$rootScope.previousPath !== "/login" &&
              this.$rootScope.previousPath !== "/unauthorised"
            ) {
              var previousPath = this.$rootScope.previousPath;
              this.$rootScope.previousPath = null;
              this.$location.url(previousPath);
            } else {
              var activeEntity = this.$cookies.get("activeEntity");
              if (activeEntity) {
                this.entityService.setUserEntity(Number(activeEntity)).save(
                  (msgHandler: interfaces.applicationcore.IMessageHandler) => {
                    this.redirectAfterLogin();
                  },
                  (failureData) => {
                    this.redirectAfterLogin();
                  }
                );
              } else {
                this.redirectAfterLogin();
              }
            }
          }
        }
      }

      redirectAfterLogin() {
        this.$state.go("auth.Default");
      }

      failureLogin(errorResponse): void {
        this.generalService.displayMessageHandler(
          <interfaces.applicationcore.IMessageHandler>errorResponse.data
        );
      }

      loadDescriptions() {
        this.descriptionService.loadDescriptions();
      }
    }
  }

  angular
    .module("app")
    .controller("azureADCtrl", controllers.applicationcore.azureADCtrl);
}
